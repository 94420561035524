.email-form[data-v-158d14] {
  max-width: 400px;
  margin: 0 auto;
}

.error[data-v-158d14] {
  color: red;
  font-size: .875em;
}

.email-form[data-v-1106b3] {
  max-width: 400px;
  margin: 0 auto;
}

.error[data-v-1106b3] {
  color: red;
  font-size: .875em;
}
/*# sourceMappingURL=index.b5681f68.css.map */
